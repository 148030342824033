import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "../CSS/Modal.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RoleListGetAction } from "../store/UserAction/RoleList";
import { CreateUser } from "../store/UserAction/CreateUser";
import { toast } from "react-toastify";
const EditModal = (props) => {
  const dispatch = useDispatch();
  const { id, ...userWithoutId } = props.user;

  useEffect(() => {
    setUserData({
      ...userWithoutId,
    });
  }, [props]);

  const [userData, setUserData] = useState({
    ...userWithoutId,
    addedById: 0,
    updateUser: true,
  });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const RoleList = useSelector(
    (state) => state?.RoleListReducer?.RoleList?.responseData
  );
  useEffect(() => {
    if (RoleList?.length > 0) {
      let role = RoleList.find((e) => e?.name === userData.role);
      setUserData((e) => ({ ...e, roleId: role?.id }));
    }
  }, [RoleList]);

  useEffect(() => {
    dispatch(RoleListGetAction());
    const storedUserData = localStorage.getItem("userData");
    const data = JSON.parse(storedUserData);
    setUserData({ ...userData, addedById: data?.id });
  }, []);

  const roleOptions = RoleList?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  useEffect(() => {
    if (props.isModalOpen) {
      handleShow();
    }
  }, [props.isModalOpen]);

  const handleCreateUser = () => {
    let roleId = roleOptions?.filter(
      (item) => item?.label === userData?.role
    )[0]?.value;
    const data = {
      addedById: userData.addedById,
      address: userData.address,
      email: userData.email,
      firstName: userData.firstName,
      phoneNumber: userData.phoneNumber,
      pinCode: userData.pinCode,
      roleId: userData.roleId,
      secondName: userData.secondName,
      updateUser: true,
    };
    dispatch(
      CreateUser(data, props.closeEditModal, props.currentPage, "Edit", roleId)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const selectedRoleOption = userData.role
    ? roleOptions.find((e) => e?.label === userData.role)
    : null;
  return (
    <div>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} className="name">
              First Name
            </Col>
            <Col md={8}>
              <input
                type="text"
                className={`InputText1 ${userData.firstName ? "" : "disabled"}`}
                name="firstName"
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
                value={userData.firstName}
                disabled
              />
            </Col>
            <Col md={3} className="name">
              Last Name
            </Col>
            <Col md={8}>
              <input
                type="text"
                className={`InputText1 ${
                  userData.secondName ? "" : "disabled"
                }`}
                autoComplete="off"
                name="secondName"
                disabled
                onChange={(e) => handleInputChange(e)}
                value={userData.secondName}
              />
            </Col>
            <Col md={3} className="name">
              Role
            </Col>

            <Col md={8}>
              <input
                type="text"
                value={selectedRoleOption ? selectedRoleOption.label : ""}
                className={`InputText1 ${
                  userData.selectedRoleOption ? "" : "disabled"
                }`}
                disabled
              />
            </Col>
            <Col md={3} className="name">
              Mobile No
            </Col>
            <Col md={8}>
              <input
                type="text"
                className="InputText1"
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
                name="phoneNumber"
                value={userData.phoneNumber}
                maxLength={10}
              />
            </Col>
            <Col md={3} className="name">
              Email ID
            </Col>
            <Col md={8}>
              <input
                type="text"
                className={`InputText1 ${userData.email ? "" : "disabled"}`}
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
                name="email"
                value={userData.email}
                disabled
              />
            </Col>
            <Col md={3} className="name">
              Address
            </Col>
            <Col md={8}>
              <input
                type="text"
                className="InputText1"
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
                value={userData.address}
                name="address"
              />
            </Col>
            <Col md={3} className="name">
              Pincode
            </Col>
            <Col md={8}>
              <input
                type="text"
                className="InputText1"
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
                value={userData.pinCode}
                name="pinCode"
                maxLength={6}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="Cancel"
            onClick={props.closeEditModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="master-primary-button"
            onClick={() => handleCreateUser()}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditModal;

import React from "react";

export default function AddButton({ onClick, btnName, className }) {
  return (
    <>
      <button
        className={`btn btn-outline-primary ${className}`}
        type="button"
        onClick={onClick}
      >
        {btnName}
      </button>
    </>
  );
}

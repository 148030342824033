import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "../CSS/Modal.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectStyle } from "../Utils/Helper/commoncsss";
import { TypeListGetAction } from "../store/ProjectAction/ProjectType";
import { CreateProjectAPI } from "../store/ProjectAction/CreateProjectReducer";

const ProjectEditModal = (props) => {
  const dispatch = useDispatch();
  const { pm, addedOn, ...ProjectData } = props.project;

  const [userData, setUserData] = useState({
    ...ProjectData,
    addedById: 0,
  });

  useEffect(() => {
    setUserData({
      ...ProjectData,
    });
  }, [props]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(TypeListGetAction());
    const storedUserData = localStorage.getItem("userData");
    const data = JSON.parse(storedUserData);
    setUserData({ ...userData, addedById: data.id });
  }, []);

  const TypeList = useSelector(
    (state) => state?.ProjectType?.TypeList?.responseData
  );

  const typeOptions = TypeList?.map((type) => ({
    value: type.id,
    label: type.name,
  }));
  const handleCreateProject = () => {
    let projectId = typeOptions?.filter(
      (item) => item?.value === userData?.projectModel
    )[0]?.value;
    const data = {
      addedById: userData?.addedById,
      communicationMode: userData?.communicationMode,
      id: userData?.id,
      location: userData?.location,
      name: userData?.name,
      projectModel: userData?.projectModelId,
    };

    dispatch(
      CreateProjectAPI(
        data,
        props.closeEditModal,
        props.currentPage,
        "Edit",
        projectId
      )
    );
  };

  useEffect(() => {
    if (TypeList?.length > 0) {
      let type = TypeList.find((e) => e?.label === userData.type);

      if (type) {
        setUserData((e) => ({ ...e, projectModel: type.id }));
      }
    }
  }, [TypeList]);

  const options = [
    { label: "NBIOT", value: "NBIOT" },
    { label: "LORAWAN", value: "LORAWAN" },
  ];

  useEffect(() => {
    if (props.isModalOpen) {
      handleShow();
    }
  }, [props.isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const selectedOption = userData.projectModel
    ? typeOptions.find((e) => e.label === userData.projectModel)
    : null;
  const selectedModel = userData.communicationMode
    ? options.find((e) => e.label === userData.communicationMode)
    : null;
  return (
    <div>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} className="name">
              Project
            </Col>
            <Col md={8}>
              <input
                type="text"
                className={`InputText1 ${userData.name ? "" : "disabled"}`}
                autoComplete="off"
                name="name"
                disabled
                onChange={(e) => handleInputChange(e)}
                value={userData.name}
              />
            </Col>
            <Col md={3} className="name">
              Location
            </Col>
            <Col md={8}>
              <input
                type="text"
                className="InputText1"
                autoComplete="off"
                name="location"
                onChange={(e) => handleInputChange(e)}
                value={userData.location}
              />
            </Col>
            <Col md={3} className="name">
              Model
            </Col>

            <Col md={8}>
              <input
                type="text"
                value={selectedOption ? selectedOption.label : ""}
                className={`InputText1 ${
                  userData.selectedOption ? "" : "disabled"
                }`}
                disabled
              />
            </Col>
            <Col md={3} className="name">
              Communication Mode
            </Col>
            <Col md={8}>
              <input
                type="text"
                value={selectedModel ? selectedModel.label : ""}
                className={`InputText1 ${
                  userData.selectedModel ? "" : "disabled"
                }`}
                disabled
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="Cancel"
            onClick={props.closeEditModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="master-primary-button"
            onClick={() => handleCreateProject()}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectEditModal;

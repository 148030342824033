import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  ViewProjectById: [],
  isLoading: false,
  isError: "",
};

const ViewProjectByIdSlice = createSlice({
  name: "ViewProjectById",
  initialState: initialListState,
  reducers: {
    // ViewProjectById Get API

    ViewProjectByIdAPI(state) {
      state.isLoading = true;
    },
    ViewProjectByIdAPISuccess(state, action) {
      state.isLoading = false;
      state.ViewProjectById = action.payload;
    },
    ViewProjectByIdAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const ViewProjectByIdGetAction = (projectId) => {
  return async (dispatch) => {
    try {
      dispatch(ViewProjectByIdSlice.actions.ViewProjectByIdAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/project/view-project-by-id?projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(
        ViewProjectByIdSlice.actions.ViewProjectByIdAPISuccess(
          response.data.responseData
        )
      );
    } catch (error) {
      dispatch(
        ViewProjectByIdSlice.actions.ViewProjectByIdAPIFailure(error.message)
      );
    }
  };
};

export default ViewProjectByIdSlice.reducer;

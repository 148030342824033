import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "../CSS/ViewProject.css";
import { UploadFileAPI } from "../store/ProjectAction/UploadBatch";
import { useDispatch, useSelector } from "react-redux";
import { ValidateFileAPI } from "../store/ProjectAction/ValidateFile";
import { useParams } from "react-router-dom";
import { ProdTableGetAction } from "../store/ProjectAction/ProductionTable";
function UploadExcel(props) {
  console.log({ props });
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const fileUploadHandler = (file) => {
    setFile(file);
  };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const SubmitUploadFile = () => {
    dispatch(
      ValidateFileAPI(
        props?.communicationMode,
        file,
        props?.projectID,
        props.closeUploadModal
      )
    );
    // dispatch(ProdTableGetAction(5, currentPage, atob(id)));
  };

  return (
    <>
      <div>
        <Modal show={props?.isModalUploadOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Production Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="file-input-container">
              <div class="Modal_Heading">
                File<span style={{ color: "red" }}>*</span>
              </div>
              <input
                type="file"
                id="fileInput"
                className="file-input"
                accept=".xlsx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  fileUploadHandler(file);
                }}
              />
            </div>
            <div>
              <button className="template">Download Template</button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="Cancel"
              onClick={props.closeUploadModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="master-primary-button"
              onClick={() => SubmitUploadFile()}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default UploadExcel;

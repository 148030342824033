import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  PMList: [],
  isLoading: false,
  isError: "",
};

const PMListSlice = createSlice({
  name: "PMList",
  initialState: initialListState,
  reducers: {
    // PMList Get API

    PMListAPI(state) {
      state.isLoading = true;
    },
    PMListAPISuccess(state, action) {
      state.isLoading = false;
      state.PMList = action.payload;
    },
    PMListAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const PMListGetAction = () => {
  return async (dispatch) => {
    try {
      dispatch(PMListSlice.actions.PMListAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/project/list-of-pm`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(PMListSlice.actions.PMListAPISuccess(response.data));
    } catch (error) {
      dispatch(PMListSlice.actions.PMListAPIFailure(error.message));
    }
  };
};

export default PMListSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  RoleList: [],
  isLoading: false,
  isError: "",
};

const RoleListSlice = createSlice({
  name: "RoleList",
  initialState: initialListState,
  reducers: {
    // RoleList Get API

    RoleListAPI(state) {
      state.isLoading = true;
    },
    RoleListAPISuccess(state, action) {
      state.isLoading = false;
      state.RoleList = action.payload;
    },
    RoleListAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const RoleListGetAction = () => {
  return async (dispatch) => {
    try {
      dispatch(RoleListSlice.actions.RoleListAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/user/role-listing`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(RoleListSlice.actions.RoleListAPISuccess(response.data));
    } catch (error) {
      dispatch(RoleListSlice.actions.RoleListAPIFailure(error.message));
    }
  };
};

export default RoleListSlice.reducer;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  LoginErrorMessage: "",
  isLoading: true,
  LoginResult: "",
};

const LoginApiSlice = createSlice({
  name: "LoginApiSlice",
  initialState: initialState,
  reducers: {
    LoginApiInitial(state) {
      state.isLoading = true;
    },
    LoginApiSuccess(state, action) {
      state.isLoading = false;
      state.LoginResult = action.payload;
    },
    LoginApiFailure(state, action) {
      state.isLoading = false;
      state.LoginErrorMessage = action.payload;
    },
  },
});

export const SigninAction = (data) => {
  const data1 = {
    deviceType: data?.deviceType,
    email: data?.email,
    password: data?.password,
  };
  return (dispatch) => {
    dispatch(SignAPiActions.LoginApiInitial());

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data1),
      url: `${process.env.REACT_APP_API_BASEURL}/sign-in`,
    })
      .then((res) => {
        if (res?.data?.responseStatus?.statusCode === 0) {
          // Error case
          dispatch(
            SignAPiActions.LoginApiFailure(res.data.responseData.message)
          );
          toast.error(`Error: ${res.data.responseData.message}`);
        } else {
          // Success case
          dispatch(SignAPiActions.LoginApiSuccess(res.data));
          localStorage.setItem("token", res.data.responseData.bearerToken);
          localStorage.setItem(
            "userData",
            JSON.stringify(res.data.responseData)
          );
          data.navigate(data.pathName);
          toast.success("Login successful!");
        }
      })
      .catch((err) => {
        dispatch(SignAPiActions.LoginApiFailure(err.message));
        toast.error(`Error: ${err.message}`);
      });
  };
};
export const SignAPiActions = LoginApiSlice.actions;
export default LoginApiSlice.reducer;

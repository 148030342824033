import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  ListOfProject: [],
  isLoading: false,
  isError: "",
  totalPages: 0,
};

const ListOfProjectSlice = createSlice({
  name: "ListOfProject",
  initialState: initialListState,
  reducers: {
    // Listofproject Get API

    ListOfProjectAPI(state) {
      state.isLoading = true;
    },
    ListOfProjectAPISuccess(state, action) {
      state.isLoading = false;
      state.ListOfProject = action.payload;
      state.totalPages = action.payload.count;
    },
    ListOfProjectAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const ListOfProjectGetAction = (projectId, currentPage, searchQuery) => {
  return async (dispatch) => {
    let baseURL = `${process.env.REACT_APP_API_BASEURL}/project/list-of-users-by-project?limit=5&page=${currentPage}&projectId=${projectId}`;
    if (searchQuery) {
      baseURL += `&search=${searchQuery}`;
    }
    try {
      dispatch(ListOfProjectSlice.actions.ListOfProjectAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      dispatch(
        ListOfProjectSlice.actions.ListOfProjectAPISuccess(
          response.data.responseData
        )
      );
    } catch (error) {
      dispatch(
        ListOfProjectSlice.actions.ListOfProjectAPIFailure(error.message)
      );
    }
  };
};

export default ListOfProjectSlice.reducer;

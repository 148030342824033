import { combineReducers } from "redux";
import loginApiReducer from "./LoginAction/SignIn";
import tableListReducer from "./UserAction/table";
import RoleListReducer from "./UserAction/RoleList";
import CreateUserReducer from "./UserAction/CreateUser";
import DeleteUserReducer from "./UserAction/DeleteUser";
import CreateProjectReducer from "./ProjectAction/ProjectListReducer";
import CreateProject from "./ProjectAction/CreateProjectReducer";
import ProjectType from "./ProjectAction/ProjectType";
import PMList from "./ProjectAction/Listofpm";
import ProjectDelete from "./ProjectAction/ProjectDelete";
import listOfUser from "./ProjectAction/ListofuserByrole";
import ViewProjectById from "./ProjectAction/ViewProjectById";
import ViewProjectList from "./ProjectAction/ViewProjectList";
import AddUserAPI from "./ProjectAction/AdduserTable";
import ProdTableGetAction from "./ProjectAction/ProductionTable";
export default combineReducers({
  loginApiReducer: loginApiReducer,
  tableListReducer: tableListReducer,
  RoleListReducer: RoleListReducer,
  CreateUserReducer: CreateUserReducer,
  DeleteUserReducer: DeleteUserReducer,
  CreateProjectReducer: CreateProjectReducer,
  CreateProject: CreateProject,
  ProjectType: ProjectType,
  PMList: PMList,
  ProjectDelete: ProjectDelete,
  listOfUser: listOfUser,
  ViewProjectById: ViewProjectById,
  ViewProjectList: ViewProjectList,
  AddUserAPI: AddUserAPI,
  ProdTableGetAction: ProdTableGetAction,
});

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  TypeList: [],
  isLoading: false,
  isError: "",
};

const TypeListSlice = createSlice({
  name: "TypeList",
  initialState: initialListState,
  reducers: {
    // TypeList Get API

    TypeListAPI(state) {
      state.isLoading = true;
    },
    TypeListAPISuccess(state, action) {
      state.isLoading = false;
      state.TypeList = action.payload;
    },
    TypeListAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const TypeListGetAction = () => {
  return async (dispatch) => {
    try {
      dispatch(TypeListSlice.actions.TypeListAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/project/project-model-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(TypeListSlice.actions.TypeListAPISuccess(response.data));
    } catch (error) {
      dispatch(TypeListSlice.actions.TypeListAPIFailure(error.message));
    }
  };
};

export default TypeListSlice.reducer;

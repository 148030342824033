import { useState, useEffect } from "react";
import React from "react";
import Select from "react-select";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "../CSS/Modal.css";
import { useDispatch, useSelector } from "react-redux";
import { CreateProjectAPI } from "../store/ProjectAction/CreateProjectReducer";
import { TypeListGetAction } from "../store/ProjectAction/ProjectType";
import { PMListGetAction } from "../store/ProjectAction/Listofpm";
import { selectStyle } from "../Utils/Helper/commoncsss";
import { toast } from "react-toastify";
function MyProjectModalComponent(props) {
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    addedById: 0,
  });
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleCreateProject = () => {
    if (
      !userData.addedById ||
      !userData.name?.trim() ||
      !userData.location?.trim() ||
      !userData.projectModel ||
      !userData.communicationMode ||
      !userData.pmId
    ) {
      toast.error("Please fill in all required fields");
      return;
    }

    dispatch(
      CreateProjectAPI(
        userData,
        () => {
          resetUserData();
          toast.success("Project created successfully");
          props.closeModal();
        },
        props.currentPage,
        "Create",
        null
      )
    );
  };

  const resetUserData = () => {
    const initialUserData = {
      addedById: null,
      name: "",
      location: "",
      projectModel: "",
      communicationMode: "",
      pmId: "",
    };

    setUserData(initialUserData);
  };

  const handleChange = (communicationMode) => {
    setUserData({
      ...userData,
      communicationMode: communicationMode.value,
    });
  };

  const options = [
    { label: "NBIOT", value: "NBIOT" },
    { label: "LORAWAN", value: "LORAWAN" },
  ];

  useEffect(() => {
    dispatch(TypeListGetAction());
    const storedUserData = localStorage.getItem("userData");
    const data = JSON.parse(storedUserData);
    setUserData({ ...userData, addedById: data.id });
  }, []);

  useEffect(() => {
    dispatch(PMListGetAction());
  }, []);

  const TypeList = useSelector(
    (state) => state?.ProjectType?.TypeList?.responseData
  );
  const PMList = useSelector((state) => state?.PMList?.PMList?.responseData);

  useEffect(() => {
    if (props.isModalOpen) {
      handleShow();
    }
  }, [props.isModalOpen]);

  return (
    <div>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} className="name">
              Project<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                className="InputText1"
                name="name"
                autoComplete="off"
              />
            </Col>
            <Col md={3} className="name">
              Location<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleInputChange(e)}
                type="text"
                className="InputText1"
                name="location"
                autoComplete="off"
              />
            </Col>
            <Col md={3} className="name">
              Model<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <Select
                options={TypeList?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
                onChange={(e) =>
                  setUserData({ ...userData, projectModel: e.value })
                }
                className="InputText"
                styles={selectStyle}
              />
            </Col>
            <Col md={3} className="name">
              PM<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <Select
                options={PMList?.map((pm) => ({
                  value: pm.id,
                  label: pm.name,
                }))}
                onChange={(e) => setUserData({ ...userData, pmId: e.value })}
                className="InputText"
                styles={selectStyle}
              />
            </Col>
            <Col md={3} className="name">
              Communication Mode<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <Select
                options={options}
                onChange={handleChange}
                className="InputText"
                styles={selectStyle}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="Cancel"
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="master-primary-button"
            onClick={handleCreateProject}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyProjectModalComponent;

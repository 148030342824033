import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { DeleteProjectAction } from "../store/ProjectAction/ProjectDelete";
import "../CSS/DeleteModal.css";
import { useLocation } from "react-router-dom";
import { DeleteUserAction } from "../store/UserAction/DeleteUser";

function DeleteModal({
  isDeleteModalhandle,
  currentPage,
  setModalDeleteHandle,
  setCurrentPage,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleShowDeleteModal = async () => {
    if (location.pathname === "/project") {
      const handle = await dispatch(
        DeleteProjectAction(isDeleteModalhandle?.userId, setCurrentPage)
      );
    } else if (location.pathname === "/user") {
      const handle = await dispatch(
        DeleteUserAction(isDeleteModalhandle?.userId, setCurrentPage)
      );
    }
    setModalDeleteHandle((previous) => ({
      ...previous,
      isOpen: false,
      userId: 0,
    }));
  };

  const handleCloseDeleteModal = () => {
    setModalDeleteHandle((previous) => ({
      ...previous,
      isOpen: false,
      userId: 0,
    }));
  };

  return (
    <>
      <Modal show={isDeleteModalhandle?.isOpen} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {location.pathname === "/project"
              ? "Delete Project"
              : location.pathname === "/user"
              ? "Delete User"
              : "Default Title"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {location.pathname === "/project"
            ? "Are you sure you want to delete this project?"
            : location.pathname === "/user"
            ? "Are you sure you want to delete this user?"
            : "Default Body Content"}
        </Modal.Body>
        <Modal.Footer>
          <Button className="CancelBtn" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button className="DeleteBtn" onClick={handleShowDeleteModal}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;

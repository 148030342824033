import React from "react";
import { Pagination } from "react-bootstrap";

const MyPagination = ({ totalPages, currentPage, onPageChange }) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];

    const totalWholePages = Math.ceil(totalPages);

    for (let i = 1; i <= totalWholePages; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  return (
    <Pagination style={{ position: "relative", right: "3rem" }}>
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {generatePageNumbers()}
      <Pagination.Ellipsis />
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= Math.ceil(totalPages)}
      />
    </Pagination>
  );
};

export default MyPagination;

import React, { useEffect, useState } from "react";
import "../CSS/User.css";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import MyPagination from "../Pagination/pagination";
import { useDispatch, useSelector } from "react-redux";
import { TopTableGetAction } from "../store/UserAction/table";
import { IoMdMore } from "react-icons/io";
import { DeleteUserAction } from "../store/UserAction/DeleteUser";
import UserEditModal from "../Modal/UserEditModal";
import AddButton from "../commonComponents/AddButton";
import MyModalComponent from "../Modal/MyModalComponent";
import DeleteModal from "../Modal/DeleteModal";
import Backdrop from "../commonComponents/Backdrop";
import NoUserImg from "../assets/NoUserImg.svg";
import { CheckAccessCode, users_access_code } from "../Utils/Helper/constant";
function User() {
  const [showAction, setShowAction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userObj, setUserObj] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [isDeleteModalhandle, setModalDeleteHandle] = useState({
    userId: 0,
    isOpen: false,
  });
  const handleAddUserClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const closeEditModal = () => {
    setModalEditOpen(false);
  };
  const dispatch = useDispatch();

  const TopTable = useSelector(
    (state) => state?.tableListReducer?.TopTable?.responseData?.users
  );

  const totalAllPages = useSelector(
    (state) => state?.tableListReducer?.totalPages
  );

  const onPageChange = (pages) => {
    setCurrentPage(pages);
  };
  const handleEditClick = () => {
    setModalEditOpen(true);
  };

  const handleDelete = async (userId) => {
    setModalDeleteHandle({
      isOpen: true,
      userId: userId,
    });
    setShowAction(null);
    setAction(!action);
  };
  useEffect(() => {
    dispatch(TopTableGetAction(5, currentPage, searchText));
  }, [currentPage, action]);

  return (
    <>
      <Header
        addButton={
          <AddButton
            className={"add_userbtn"}
            btnName={"Add User"}
            onClick={() => handleAddUserClick()}
          />
        }
      />
      <SideBar />
      <table class="table table_one">
        <thead>
          <tr>
            <th className="UserTable-First" scope="col">
              User Id{" "}
            </th>
            <th className="UserTable" scope="col">
              Name
            </th>
            <th className="UserTable" scope="col">
              Role
            </th>
            <th className="UserTable" scope="col">
              Address
            </th>
            <th className="UserTable" scope="col">
              Mobile no
            </th>
            <th className="UserTable" scope="col">
              Email
            </th>
            <th className="UserTable" scope="col">
              Pincode
            </th>
            <th className="UserTable-Last" scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {TopTable && TopTable.length > 0 ? (
            TopTable.map((user, idx) => (
              <tr key={user.id}>
                <td scope="row">
                  <a>{`#UR00${user.id}`}</a>
                </td>
                <td>{user.firstName}</td>
                <td>{user.role}</td>
                <td>{user.address ? user.address : "-"}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.email}</td>
                <td>{user.pinCode}</td>
                <td>
                  <p className="action_para">
                    <IoMdMore
                      onClick={() => {
                        setOpenBackdrop(true);
                        setUserObj(user);
                        setShowAction((prevIdx) =>
                          prevIdx === idx ? null : idx
                        );
                      }}
                      className="more_action_icon"
                    />
                    {showAction === idx && (
                      <div className="more_actions_div">
                        {CheckAccessCode(
                          users_access_code?.user_edit_users
                        ) && (
                          <div
                            className="more_action_li"
                            onClick={() => {
                              handleEditClick();
                              setShowAction(null);
                              setOpenBackdrop(false);
                            }}
                          >
                            Edit
                          </div>
                        )}

                        {CheckAccessCode(
                          users_access_code?.user_delete_users
                        ) && (
                          <div
                            className="more_action_li"
                            onClick={() => handleDelete(user.id)}
                          >
                            Delete
                          </div>
                        )}
                      </div>
                    )}
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="NoData_img" colSpan="8">
                <img src={NoUserImg} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalAllPages > 5 ? (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <MyPagination
            totalPages={totalAllPages / 5}
            currentPage={currentPage}
            onPageChange={(pageNumber) => onPageChange(pageNumber)}
          />
        </div>
      ) : null}
      <MyModalComponent
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isModalEditOpen={isModalEditOpen}
        currentPage={currentPage}
      />
      <DeleteModal
        isDeleteModalhandle={isDeleteModalhandle}
        currentPage={currentPage}
        setModalDeleteHandle={setModalDeleteHandle}
        setCurrentPage={setCurrentPage}
      />
      <UserEditModal
        closeEditModal={closeEditModal}
        isModalOpen={isModalEditOpen}
        user={userObj}
        currentPage={currentPage}
      />

      {openBackdrop && (
        <Backdrop
          handleClick={() => {
            setOpenBackdrop(false);
            setShowAction(false);
          }}
        />
      )}
    </>
  );
}

export default User;

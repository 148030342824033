import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialTableState = {
  ProductionTable: [],
  isLoading: false,
  isError: "",
  createSuccess: false,
  totalPages: 0,
};

const ProductionTableSlice = createSlice({
  name: "ProdTable",
  initialState: initialTableState,
  reducers: {
    // ProdTable Get API

    ProdtableAPI(state) {
      state.isLoading = true;
    },
    ProdTableAPISuccess(state, action) {
      state.isLoading = false;
      state.ProductionTable = action.payload;
      state.createSuccess = true;
      // state.totalPages = action.payload.responseData.count;
    },
    ProdTableAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const ProdTableGetAction = (
  limit,
  currentPage,
  projectId,
  searchQuery
) => {
  return async (dispatch) => {
    let baseURL = `${process.env.REACT_APP_API_BASEURL}/production/list-of-production-batch-by-project?limit=${limit}&page=${currentPage}&projectId=${projectId}`;
    if (searchQuery) {
      baseURL += `&search=${searchQuery}`;
    }
    try {
      dispatch(ProductionTableSlice.actions.ProdtableAPI());
      const token = localStorage.getItem("token");
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(
        ProductionTableSlice.actions.ProdTableAPISuccess(
          response?.data?.responseData?.productionData
        )
      );
    } catch (error) {
      dispatch(ProductionTableSlice.actions.ProdTableAPIFailure(error.message));
    }
  };
};
export default ProductionTableSlice.reducer;

import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "../CSS/DeleteModal.css";
import { RemoveUserAction } from "../store/ProjectAction/RemoveTable";
function RemoveModal({
  isRemoveModalhandle,
  setRemoveModalHandle,
  setCurrentPage,
}) {
  const dispatch = useDispatch();
  const RemoveHandle = () => {
    dispatch(
      RemoveUserAction(
        isRemoveModalhandle?.projectID,
        isRemoveModalhandle?.userId,
        setCurrentPage
      )
    );
    handleClose();
  };

  const handleClose = () => {
    setRemoveModalHandle(false);
  };
  return (
    <>
      <Modal show={isRemoveModalhandle?.isOpen}>
        <Modal.Header closeButton>
          <Modal.Title>Remove User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this user ?</Modal.Body>
        <Modal.Footer>
          <Button className="CancelBtn" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={RemoveHandle} className="DeleteBtn">
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveModal;

import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "../CSS/Modal.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RoleListGetAction } from "../store/UserAction/RoleList";
import { CreateUser } from "../store/UserAction/CreateUser";
import { selectStyle } from "../Utils/Helper/commoncsss";
import { toast } from "react-toastify";

const MyModalComponent = (props) => {
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(true);
  const [errors, setErrors] = useState(false);

  const [userData, setUserData] = useState({
    addedById: 0,
    updateUser: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });

    if (name === "phoneNumber") {
      const phoneRegex = /^[0-9]{10}$/;
      const isValid = phoneRegex.test(value);
      setValidPhoneNumber(isValid);
    }
  };
  const RoleList = useSelector(
    (state) => state?.RoleListReducer?.RoleList?.responseData
  );

  const handleEmailChange = (e) => {
    setUserData({
      ...userData,
      email: e.target.value,
    });
    const inputEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
  };

  const handleCreateUser = () => {
    if (
      !userData.addedById ||
      !userData.email?.trim() ||
      !userData.firstName?.trim() ||
      !userData.roleId ||
      !userData.secondName?.trim()
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    dispatch(
      CreateUser(
        userData,
        () => {
          resetUserData();
          toast.success("User created successfully");
          props.closeModal();
        },
        props.currentPage,
        "Create",
        null
      )
    );
  };

  const resetUserData = () => {
    const initialUserData = {
      addedById: null,
      email: "",
      firstName: "",
      roleId: "",
      secondName: "",
    };

    setUserData(initialUserData);
  };

  useEffect(() => {
    dispatch(RoleListGetAction());
    const storedUserData = localStorage.getItem("userData");
    const data = JSON.parse(storedUserData);
    setUserData({ ...userData, addedById: data.id });
  }, []);

  useEffect(() => {
    if (props.isModalOpen) {
      handleShow();
    }
  }, [props.isModalOpen]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      <Modal show={props.isModalOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} className="name">
              First Name<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                className="InputText1"
                name="firstName"
                autoComplete="off"
              />
            </Col>
            <Col md={3} className="name">
              Last Name<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleInputChange(e)}
                type="text"
                className="InputText1"
                name="secondName"
                autoComplete="off"
              />
            </Col>
            <Col md={3} className="name">
              Role<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <Select
                options={RoleList?.map((role) => ({
                  value: role.id,
                  label: role.name,
                }))}
                onChange={(e) => setUserData({ ...userData, roleId: e.value })}
                className="InputText"
                styles={selectStyle}
              />
            </Col>
            <Col md={3} className="name">
              Mobile No
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleInputChange(e)}
                type="text"
                className={`InputText1 ${isValidPhoneNumber ? "" : "Invalid"}`}
                name="phoneNumber"
                autoComplete="off"
                maxLength={10}
              />

              {!isValidPhoneNumber && (
                <p style={{ color: "red" }}>
                  Please enter a valid 10-digit phone number.
                </p>
              )}
            </Col>
            <Col md={3} className="name">
              Email ID<span style={{ color: "red" }}>*</span>
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleEmailChange(e)}
                type="text"
                className="InputText1"
                name="email"
                autoComplete="off"
              />

              {!isValidEmail && (
                <div className="error-message" style={{ color: "red" }}>
                  Invalid Email ID
                </div>
              )}
            </Col>
            <Col md={3} className="name">
              Address
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleInputChange(e)}
                type="text"
                className="InputText1"
                name="address"
                autoComplete="off"
              />
            </Col>
            <Col md={3} className="name">
              Pincode
            </Col>
            <Col md={8}>
              <input
                onChange={(e) => handleInputChange(e)}
                type="text"
                className="InputText1"
                name="pinCode"
                autoComplete="off"
                maxLength={6}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="Cancel"
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="master-primary-button"
            onClick={handleCreateUser}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyModalComponent;

import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import AddButton from "../commonComponents/AddButton";
import MyProjectModalComponent from "../Modal/MyProjectModalComponent";
import { ProjectListGetAction } from "../store/ProjectAction/ProjectListReducer";
import { IoMdMore } from "react-icons/io";
import ProjectEditModal from "../Modal/ProjectEditModal";
import MyPagination from "../Pagination/pagination";
import DeleteModal from "../Modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import Backdrop from "../commonComponents/Backdrop";
import moment from "moment";
import NoDataImg from "../assets/NoDataImg.svg";
import "../CSS/project.css";
import { CheckAccessCode, project_access_code } from "../Utils/Helper/constant";
function Project() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showAction, setShowAction] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isDeleteModalhandle, setModalDeleteHandle] = useState({
    userId: 0,
    isOpen: false,
  });
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [projectObj, setProjectObj] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleViewClick = (projectId) => {
    const base4 = btoa(projectId);
    navigate(`/ViewProject/${base4}`);
    setShowAction(null);
    setCurrentProjectId(projectId);
  };

  const dispatch = useDispatch();

  const onPageChange = (pages) => {
    setCurrentPage(pages);
  };

  const ProjectList = useSelector(
    (state) => state?.CreateProjectReducer?.ProjectList?.projects
  );
  const totalAllPages = useSelector(
    (state) => state?.CreateProjectReducer?.totalPages
  );

  const handleAddProjectClick = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEditClick = () => {
    setModalEditOpen(true);
  };

  const closeEditModal = () => {
    setModalEditOpen(false);
  };

  useEffect(() => {
    dispatch(ProjectListGetAction(5, currentPage, searchText));
  }, [currentPage]);

  const handleDelete = (userId) => {
    setModalDeleteHandle({
      isOpen: true,
      userId: userId,
    });
    setShowAction(null);
    setAction(!action);
  };

  return (
    <>
      <Header
        addButton={
          <AddButton
            className={"add_userbtn"}
            btnName={"Add Project"}
            onClick={() => handleAddProjectClick()}
          />
        }
      />
      <SideBar />

      <table className="table table_one">
        <thead>
          <tr>
            <th className="ProjectTable-First " scope="col">
              Project Id
            </th>
            <th className="ProjectTable" scope="col">
              Project Name
            </th>
            <th className="ProjectTable" scope="col">
              Project Location
            </th>
            <th className="ProjectTable" scope="col">
              Model
            </th>
            <th className="ProjectTable" scope="col">
              Communication Mode
            </th>
            <th className="ProjectTable" scope="col">
              Created Date
            </th>
            <th className="ProjectTable" scope="col">
              Created Time
            </th>
            <th className="ProjectTable-Last" scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(ProjectList) && ProjectList.length > 0 ? (
            ProjectList.map((project, idx) => {
              return (
                <tr key={project.id}>
                  <td
                    className="projectId"
                    onClick={() => handleViewClick(project.id)}
                  >
                    #PR00{project.id}
                  </td>{" "}
                  <td className="ProjectID">{project?.name}</td>
                  <td className="ProjectID">{project?.location}</td>
                  <td className="ProjectID">{project?.projectModel}</td>
                  <td className="ProjectID">{project?.communicationMode}</td>
                  <td className="ProjectID">
                    {moment(project.addedOn).format("DD/MM/YYYY")}
                  </td>
                  <td className="ProjectID">
                    {moment(project.addedOn).format(" h:mm:ss")}{" "}
                  </td>
                  <td>
                    {" "}
                    <p className="action_para">
                      <IoMdMore
                        onClick={() => {
                          setOpenBackdrop(true);
                          setProjectObj(project);
                          setShowAction((prevIdx) =>
                            prevIdx === idx ? null : idx
                          );
                        }}
                        className="more_action_icon"
                      />
                      {showAction === idx && (
                        <div className="more_actions_div">
                          {CheckAccessCode(
                            project_access_code?.project_view_project
                          ) && (
                            <div
                              className="more_action_li"
                              onClick={() => handleViewClick(project.id)}
                            >
                              View
                            </div>
                          )}
                          {CheckAccessCode(
                            project_access_code?.project_edit_project
                          ) && (
                            <div
                              className="more_action_li"
                              onClick={() => {
                                handleEditClick();
                                setShowAction(null);
                                setOpenBackdrop(false);
                              }}
                            >
                              Edit
                            </div>
                          )}

                          {CheckAccessCode(
                            project_access_code?.project_delete_project
                          ) && (
                            <div
                              className="more_action_li"
                              onClick={() => handleDelete(project.id)}
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      )}
                    </p>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="NoData_img" colSpan="8">
                <img src={NoDataImg} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {totalAllPages > 5 ? (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <MyPagination
            totalPages={totalAllPages / 5}
            currentPage={currentPage}
            onPageChange={(pageNumber) => onPageChange(pageNumber)}
          />
        </div>
      ) : null}
      <MyProjectModalComponent
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        currentPage={currentPage}
      />
      <DeleteModal
        isDeleteModalhandle={isDeleteModalhandle}
        currentPage={currentPage}
        setModalDeleteHandle={setModalDeleteHandle}
        setCurrentPage={setCurrentPage}
      />

      <ProjectEditModal
        closeEditModal={closeEditModal}
        isModalOpen={isModalEditOpen}
        project={projectObj}
        currentPage={currentPage}
      />

      {openBackdrop && (
        <Backdrop
          handleClick={() => {
            setOpenBackdrop(false);
            setShowAction(false);
          }}
        />
      )}
    </>
  );
}

export default Project;

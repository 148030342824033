import React from "react";
import "../CSS/SideBar.css";
import { NavLink, useNavigate } from "react-router-dom";
import briefcase from "../assets/briefcase.png";
import global from "../assets/global.png";
import report from "../assets/report.png";
import writingPad from "../assets/writingPad.png";
import User from "../assets/User.png";
import Vector from "../assets/Vector.png";
import { useLocation } from "react-router-dom";
import { CheckAccessCode, sidebar_access_code } from "../Utils/Helper/constant";

function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();

  const handlerLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const handleBackButtonClick = () => {
    navigate("/project");
  };
  return (
    <>
      <div
        class="sidebar sidebar-show"
        style={{ position: "fixed", width: "15%" }}
      >
        <ul class="sidebar-nav">
          {CheckAccessCode(sidebar_access_code?.project_users) && (
            <li
              class="nav-item nav-group"
              style={{
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              <NavLink
                className="SidebarHeading"
                class="nav-link"
                to="/project"
              >
                <img className="project_img" src={briefcase} /> Projects
              </NavLink>
            </li>
          )}
          {/* <li class="nav-item nav-group" style={{ marginBottom: "2rem" }}>
            <NavLink
              style={{
                color: "white",
                fontSize: "20px",
                marginLeft: "2rem",
                textDecoration: "none",
              }}
              class="nav-link"
              to="/tickets"
            >
              <img src={writingPad} /> Tickets
            </NavLink>
          </li>
          <li class="nav-item nav-group" style={{ marginBottom: "2rem" }}>
            <NavLink
              style={{
                color: "white",
                fontSize: "20px",
                marginLeft: "2rem",
                textDecoration: "none",
              }}
              class="nav-link "
              to="logs"
            >
              <img src={global} /> Logs
            </NavLink>
          </li>
          <li class="nav-item nav-group" style={{ marginBottom: "2rem" }}>
            <NavLink
              style={{
                color: "white",
                fontSize: "20px",
                marginLeft: "2rem",
                textDecoration: "none",
              }}
              class="nav-link "
              to="logs"
            >
              <img src={report} /> Reports
            </NavLink>
          </li> */}
          {CheckAccessCode(sidebar_access_code?.sidebar_users) && (
            <li class="nav-item nav-group">
              <NavLink className="SidebarHeading2" class="nav-link" to="/user">
                <img src={User} /> Users
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div>
        {location.pathname.startsWith("/ViewProject") ? (
          <button className="logout_btn" onClick={handleBackButtonClick}>
            <img className="logout" src={Vector} />
            Back
          </button>
        ) : (
          <button className="logout_btn" onClick={handlerLogout}>
            <img className="logout" src={Vector} />
            Logout
          </button>
        )}
      </div>
    </>
  );
}

export default SideBar;

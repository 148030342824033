import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectListGetAction } from "./ProjectListReducer";

const initialApplicationState = {
  isLoading: false,
  CreateProjectRes: "",
  isError: "",
};

const CreateProjectSlice = createSlice({
  name: "CreateProjectList",
  initialState: initialApplicationState,
  reducers: {
    createApiRequested(state) {
      state.jobRes = "";
      state.isError = "";
    },

    createAPISuccess(state, action) {
      state.isLoading = false;
      state.CreateProjectRes = action.payload;
      state.isError = "";
    },

    createAPIFailure(state, action) {
      state.isLoading = false;
      state.CreateProjectRes = "";
      state.isError = action.payload;
    },
  },
});

export const CreateProjectAPI = (
  userData,
  closeModal,
  currentPage,
  purpose,
  projectId
) => {
  const newObj =
    purpose === "Create"
      ? {
          ...userData,
          addedById: JSON.parse(localStorage.getItem("userData"))?.id,
        }
      : {
          ...userData,
          projectType: projectId,
          addedById: JSON.parse(localStorage.getItem("userData"))?.id,
        };
  return (dispatch) => {
    dispatch(createApplicationActions.createApiRequested());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/project/create-or-update-project`,
      data: newObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res?.data?.message === "Success") {
          dispatch(createApplicationActions.createAPISuccess());
          dispatch(ProjectListGetAction(5, currentPage));
          closeModal();
        } else {
          dispatch(createApplicationActions.createAPIFailure());
        }
      })
      .catch((err) => {
        dispatch(createApplicationActions.createAPIFailure());
      });
  };
};

export const createApplicationActions = CreateProjectSlice.actions;
export default CreateProjectSlice.reducer;

import React, { useState, useEffect } from "react";
import { selectStyle } from "../Utils/Helper/commoncsss";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { ListOfUserGetAction } from "../store/ProjectAction/ListofuserByrole";
import { RoleListGetAction } from "../store/UserAction/RoleList";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/ViewProject.css";
import MyModalComponent from "./MyModalComponent";
import { AddUserAPI } from "../store/ProjectAction/AdduserTable";
import { TopTableGetAction } from "../store/UserAction/table";

function AddUserView(props) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userFormData, setUserFormData] = useState({
    userId: null,
    projectId: props.id,
  });
  const [myModalIsOpen, setMyModalIsOpen] = useState(false);
  const [Show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const RoleList = useSelector(
    (state) => state?.RoleListReducer?.RoleList?.responseData
  );

  const ListOfUser = useSelector(
    (state) => state?.listOfUser?.PMList?.responseData
  );

  useEffect(() => {
    dispatch(RoleListGetAction());
    dispatch(ListOfUserGetAction(1));
  }, []);

  const openModal = () => {
    closeModal();
    props.closeModal();
    setMyModalIsOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectionChange = (name, value) => {
    setUserFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const AddUserHandler = () => {
    dispatch(AddUserAPI(userFormData, props.id, props.closeModal));
  };

  return (
    <>
      <div>
        <Modal show={props.isModalOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={3} className="name">
                Role
              </Col>
              <Col md={8}>
                <Select
                  options={RoleList?.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  className="InputText"
                  styles={selectStyle}
                  onChange={(e) => {
                    handleSelectionChange("roleId", e?.value);
                    dispatch(ListOfUserGetAction(e?.value));
                  }}
                />
              </Col>
              <Col md={3} className="name">
                Name
              </Col>
              <Col md={8}>
                <Select
                  options={ListOfUser?.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  className="InputText"
                  styles={selectStyle}
                  onChange={(e) => handleSelectionChange("userId", e?.value)}
                />
              </Col>
            </Row>
          </Modal.Body>
          <div style={{ textAlign: "center" }}>
            <p className="ModalPara">
              if User is not in the list then,
              <a href="#" onClick={openModal}>
                Create Now
              </a>
            </p>
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="Cancel"
              onClick={props.closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="master-primary-button"
              onClick={() => AddUserHandler()}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <MyModalComponent
        isModalOpen={myModalIsOpen}
        closeModal={() => setMyModalIsOpen(false)}
        currentPage={currentPage}
      />
    </>
  );
}

export default AddUserView;

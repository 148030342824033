import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialListState = {
  ListOfUser: [],
  isLoading: false,
  isError: "",
};

const ListOfUserSlice = createSlice({
  name: "ListOfUser",
  initialState: initialListState,
  reducers: {
    // Listodfuser Get API

    ListOfUserAPI(state) {
      state.isLoading = true;
    },
    ListOfUserAPISuccess(state, action) {
      state.isLoading = false;
      state.PMList = action.payload;
    },
    ListOfUserAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const ListOfUserGetAction = (roleId) => {
  return async (dispatch) => {
    try {
      dispatch(ListOfUserSlice.actions.ListOfUserAPI());

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/project/list-of-users-by-role?roleId=${roleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(ListOfUserSlice.actions.ListOfUserAPISuccess(response.data));
    } catch (error) {
      dispatch(ListOfUserSlice.actions.ListOfUserAPIFailure(error.message));
    }
  };
};

export default ListOfUserSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ListOfProjectGetAction } from "../ProjectAction/ViewProjectList";

const initialApplicationState = {
  isLoading: false,
  AddUserRes: "",
  isError: "",
};

const AddUserSlice = createSlice({
  name: "AddUser",
  initialState: initialApplicationState,
  reducers: {
    AddUserApiRequested(state) {
      state.jobRes = "";
      state.isError = "";
    },

    AddUserAPISuccess(state, action) {
      state.isLoading = false;
      state.AddUserRes = action.payload;
      state.isError = "";
    },

    AddUserAPIFailure(state, action) {
      state.isLoading = false;
      state.AddUserRes = "";
      state.isError = action.payload;
    },
  },
});

export const AddUserAPI = (data, currentPage, closeModal) => {
  return (dispatch) => {
    dispatch(AddUserActions.AddUserApiRequested());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/project/add-user-in-project?projectId=${data.projectId}&userId=${data.userId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res?.data?.message === "Success") {
          dispatch(AddUserActions.AddUserAPISuccess());
          dispatch(ListOfProjectGetAction(data.projectId, 1));
          closeModal();
        } else {
          dispatch(AddUserActions.AddUserAPIFailure());
        }
      })
      .catch((err) => {
        dispatch(AddUserActions.AddUserAPIFailure());
      });
  };
};

export const AddUserAPI1 = (data, id, closeModal) => {
  return (dispatch) => {
    dispatch(AddUserActions.AddUserApiRequested());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/project/add-user-in-project?projectId=${id}&userId=${data}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res?.data?.message === "Success") {
          dispatch(AddUserActions.AddUserAPISuccess());
          dispatch(ListOfProjectGetAction(id, 1));
          closeModal();
        } else {
          dispatch(AddUserActions.AddUserAPIFailure());
        }
      })
      .catch((err) => {
        dispatch(AddUserActions.AddUserAPIFailure());
      });
  };
};

export const AddUserActions = AddUserSlice.actions;
export default AddUserSlice.reducer;

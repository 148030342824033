export const selectStyle = {
  control: (base) => ({
    ...base,
    margin: "1px",
    color: "white",
    border: "none",
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      border: "1px solid !important",
      borderBottom: "1px solid lightgrey !important",
      borderRadius: "0",
    },

    "&:focus-within": {
      borderBottom: "1px solid lightgrey !important",
      boxShadow: "none !important",
      outline: "none !important",
      outlineWidth: "0 !important",
    },
  }),
  container: (base) => ({
    ...base,
    position: "relative",
    top: "-8.5px",
    color: "black",
    border: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "black",
    border: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black", // Change this to the desired color
  }),
  indicatorSeparator: (base) => ({
    ...base,
    color: "blue",
    display: "none",
  }),

  placeholder: (base) => ({ ...base, display: "none" }),
  singleValue: (base) => ({
    ...base,
    // color: "black",
    fontWeight: "normal",
  }),
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { TopTableGetAction } from "./table";
import { toast } from "react-toastify";
import { AddUserAPI1 } from "../ProjectAction/AdduserTable";
const initialApplicationState = {
  isLoading: false,
  CreateUserRes: "",
  isError: "",
};

const CreateUserSlice = createSlice({
  name: "createUserList",
  initialState: initialApplicationState,
  reducers: {
    createApiRequested(state) {
      state.jobRes = "";
      state.isError = "";
    },

    createAPISuccess(state, action) {
      state.isLoading = false;
      state.CreateUserRes = action.payload;
      state.isError = "";
    },

    createAPIFailure(state, action) {
      state.isLoading = false;
      state.CreateUserRes = "";
      state.isError = action.payload;
    },
  },
});

export const CreateUser = (
  userData,
  closeModal,
  currentPage,
  purpose,
  roleId,
  id
) => {
  let newObj =
    purpose === "Create"
      ? {
          ...userData,
          addedById: JSON.parse(localStorage.getItem("userData"))?.id,
        }
      : {
          ...userData,
          roleId: roleId,
          addedById: JSON.parse(localStorage.getItem("userData"))?.id,
        };

  return (dispatch) => {
    dispatch(createApplicationActions.createApiRequested());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/user/create-or-update-user`,
      data: newObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.data.responseStatus.statusCode === 1) {
        dispatch(createApplicationActions.createAPISuccess());
        dispatch(AddUserAPI1(res.data.responseData.id, id));
        dispatch(TopTableGetAction(5, currentPage, null));
        closeModal();
      } else if (res.data.responseStatus.statusCode === 0) {
        toast.error(res.data.responseData.message);
      } else {
        dispatch(createApplicationActions.createAPIFailure());
      }
    });
  };
};

export const createApplicationActions = CreateUserSlice.actions;
export default CreateUserSlice.reducer;

import React from "react";
import "./Backdrop.css";

const Backdrop = ({ handleClick }) => {
  return (
    <div className="Loading_container" onClick={() => handleClick()}>
      Backdrop
    </div>
  );
};

export default Backdrop;

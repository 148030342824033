import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SigninAction } from "../store/LoginAction/SignIn";
import "../CSS/Login.css";
import EyeIcon from "../assets/EyeIcon.svg";
import EmailBox from "../assets/EmailBox.svg";
import openeye from "../assets/openeye.png";

function Login() {
  const dispatch = useDispatch();
  // const APIErrorMsg = useSelector((state) => state.loginApiReducer);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleLoginClick = () => {
    const pathName = "/project";
    const deviceType = "Web";
    dispatch(SigninAction({ deviceType, email, password, navigate, pathName }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
  };

  return (
    <div className="input-container">
      <Container>
        <Row>
          <Col md={7}></Col>
          <Col md={5}>
            <div className="heading py-4">Login</div>
            <div className="label">Email</div>
            <input
              className="rectangle-1"
              type="text"
              placeholder="Enter Email ID"
              autoComplete="none"
              value={email}
              onChange={(e) => handleEmailChange(e)}
            />
            <img className="Emailbox" src={EmailBox} />

            {!isValidEmail && (
              <div className="error-message" style={{ color: "red" }}>
                Invalid Email ID
              </div>
            )}
            <div className="label">Password</div>
            <input
              className="rectangle-1"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              autoComplete="none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              style={{ width: "20px" }}
              className="Eyeicon"
              onClick={togglePasswordVisibility}
              src={showPassword ? openeye : EyeIcon}
              alt={showPassword ? "Hide Password" : "Show Password"}
            />
            <button className="rectangle-999" onClick={handleLoginClick}>
              Login
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import "../CSS/ViewProject.css";
import AddButton from "../commonComponents/AddButton";
import AddUserView from "../Modal/AddUserView";
import { Row, Col, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MyPagination from "../Pagination/pagination";
import { useDispatch, useSelector } from "react-redux";
import { ViewProjectByIdGetAction } from "../store/ProjectAction/ViewProjectById";
import { ListOfProjectGetAction } from "../store/ProjectAction/ViewProjectList";
import { ProdTableGetAction } from "../store/ProjectAction/ProductionTable";
import moment from "moment";
import UploadExcel from "../Modal/UploadExcel";
import RemoveModal from "../Modal/RemoveModal";
function ViewProject() {
  // get id from url //
  const { id } = useParams();
  const [HeadingLine, setHeadingLine] = useState("Users");
  const [showAction, setShowAction] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalUploadOpen, setModalUploadOpen] = useState(false);
  const [isRemoveModalhandle, setRemoveModalHandle] = useState({
    userId: 0,
    isOpen: false,
  });
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const handleClick = (heading) => {
    setHeadingLine(heading);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const closeUploadModal = () => {
    setModalUploadOpen(false);
  };
  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };
  const ListOfProject = useSelector(
    (state) => state?.ViewProjectList?.ListOfProject
  );
  const totalAllPages = useSelector(
    (state) => state?.ViewProjectList?.totalPages
  );

  const ListOfProdTable = useSelector(
    (state) => state?.ProdTableGetAction?.ProductionTable
  );

  const ViewProject = useSelector(
    (state) => state?.ViewProjectById?.ViewProjectById
  );
  useEffect(() => {
    dispatch(ProdTableGetAction(5, currentPage, atob(id)));
    dispatch(ListOfProjectGetAction(atob(id), currentPage, searchText));
  }, [currentPage, searchText]);
  useEffect(() => {
    dispatch(ViewProjectByIdGetAction(atob(id)));
  }, [id]);
  const onPageChange = (pages) => {
    setCurrentPage(pages);
  };

  const handleRemove = (Id1) => {
    setRemoveModalHandle({
      isOpen: true,
      userId: Id1,
      projectID: atob(id),
    });
    setShowAction(null);
  };
  return (
    <>
      <Header />
      <SideBar />
      <div className="ProjectViewBox">
        <Row className="mb-3">
          <Col className="detailsAlign">
            <div className="project-id">Project ID:</div>
            <p className="projectLabel">#PR00{ViewProject?.id}</p>
          </Col>
          <Col className="detailsAlign">
            <div className="project-id">Name:</div>
            <p className="projectLabel">{ViewProject?.name}</p>
          </Col>
          <Col className="detailsAlign">
            <div className="project-id">Model: </div>
            <p className="projectLabel">{ViewProject?.projectModel}</p>
          </Col>
          <Col className="detailsAlign">
            <div className="project-id">Communication Mode: </div>
            <p className="projectLabel">{ViewProject?.communicationMode}</p>
          </Col>
        </Row>
        <Row>
          <Col className="detailsAlign">
            <div className="project-id">Location: </div>
            <p className="projectLabel">{ViewProject?.location}</p>
          </Col>
          <Col className="detailsAlign">
            <div className="project-id">Created Date:</div>
            <p className="projectLabel">
              {moment(ViewProject?.addedOn).format("DD/MM/YYYY")}
            </p>
          </Col>
          <Col className="detailsAlign">
            <div className="project-id">Created Time:</div>
            <p className="projectLabel">
              {moment(ViewProject?.addedOn).format("hh:mm:ss")}
            </p>
          </Col>
          <Col></Col>
        </Row>
      </div>

      <div className="heading-Div">
        <div
          className={`headingActiv ${
            HeadingLine === "Users" ? "activeLine" : ""
          }`}
          style={{ fontWeight: HeadingLine === "Users" ? "bold" : 200 }}
          onClick={() => handleClick("Users")}
        >
          Users
        </div>
        <div
          className={`headingActiv ${
            HeadingLine === "Production" ? "activeLine" : ""
          }`}
          style={{ fontWeight: HeadingLine === "Production" ? "bold" : 200 }}
          onClick={() => handleClick("Production")}
        >
          Production
        </div>
      </div>

      <form className="d-flex flexBox">
        <input
          className="form-control me-2 ViewSearchBox"
          type="search"
          placeholder="Search"
          aria-label="Search"
          onChange={handleInputChange}
        />
        {HeadingLine === "Users" && (
          <AddButton
            className={"view_userbtn"}
            btnName={"Add User"}
            onClick={() => setModalOpen(true)}
          />
        )}
        {HeadingLine === "Production" && (
          <AddButton
            className={"view_userbtn"}
            btnName={"Upload Excel"}
            onClick={() => setModalUploadOpen(true)}
          />
        )}
      </form>
      <div>
        {HeadingLine === "Users" ? (
          <table className="table table_one_view">
            <thead>
              <tr>
                <th
                  className={`table-header-cell table-header-cell-first`}
                  scope="col"
                >
                  User Id
                </th>
                <th className={`table-header-cell`} scope="col">
                  Name
                </th>
                <th className={`table-header-cell`} scope="col">
                  Role
                </th>
                <th className={`table-header-cell`} scope="col">
                  Address
                </th>
                <th className={`table-header-cell`} scope="col">
                  Mobile no
                </th>
                <th className={`table-header-cell`} scope="col">
                  Email
                </th>
                <th
                  className={`table-header-cell table-header-cell-last`}
                  scope="col"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {ListOfProject?.users ? (
                ListOfProject.users.map((user) => (
                  <tr key={user.id}>
                    <td className="ViewData">{`#UR00${user.id}`}</td>
                    <td className="ViewData">{`${user.firstName} ${user.secondName}`}</td>
                    <td className="ViewData">{user.role}</td>
                    <td className="ViewData">{user.address}</td>
                    <td className="ViewData">{user.phoneNumber}</td>
                    <td className="ViewData">{user.email}</td>
                    <td
                      className="removeBtn"
                      scope="row"
                      onClick={() => {
                        handleRemove(user?.id);
                      }}
                    >
                      Remove
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : HeadingLine === "Production" ? (
          <>
            <div>
              {ViewProject?.communicationMode === "NBIOT" && (
                <>
                  {/* First Table */}
                  <Table className="table table_one_view" responsive>
                    <thead>
                      <tr>
                        <th
                          className={`table-header-cell table-header-cell-first`}
                          scope="col"
                        >
                          S.No
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          PCB Serial Number
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Communication Mode
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Meter Make
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Meter Model
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Opening Reading
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Tilt Tamper
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Magnet Tamper
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Valve Control
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Uplink Time
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          IP
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          PORT
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          APN
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          APN Length
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ListOfProdTable?.length ? (
                        ListOfProdTable.map((user, index) => (
                          <tr key={user.id}>
                            <td>{index + 1}.</td>
                            <td>{user.meterNo}</td>
                            <td>{user.communicationMode}</td>
                            <td>{user.meterMake}</td>
                            <td>{user.meterModel}</td>
                            <td>{user.openingReading}</td>
                            <td>{user.tiltTamper}</td>
                            <td>{user.magnetTamper}</td>
                            <td>{user.valveControl}</td>
                            <td>{user.uplinkTime}</td>
                            <td>{user.ip}</td>
                            <td>{user.port}</td>
                            <td>{user.apn}</td>
                            <td>{user.apnLength}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
              )}

              {ViewProject?.communicationMode === "LORAWAN" && (
                <>
                  <Table className="table table_one_view" responsive>
                    <thead>
                      <tr>
                        <th
                          className={`table-header-cell table-header-cell-first`}
                          scope="col"
                        >
                          PCB Serial Number
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Communication Mode
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Meter Make
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Meter Model
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Opening Reading
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Tilt Tamper
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Magnet Tamper
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Valve Control
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          Uplink Time
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          DEV UI
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          APP UI
                        </th>
                        <th className={`table-header-cell `} scope="col">
                          APP KEY
                        </th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </Table>
                </>
              )}
            </div>
          </>
        ) : (
          <p>No data found</p>
        )}
      </div>
      {totalAllPages > 5 ? (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <MyPagination
            totalPages={totalAllPages / 5}
            currentPage={currentPage}
            onPageChange={(pageNumber) => onPageChange(pageNumber)}
          />
        </div>
      ) : null}
      <AddUserView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        id={atob(id)}
      />
      <UploadExcel
        isModalUploadOpen={isModalUploadOpen}
        closeUploadModal={closeUploadModal}
        projectID={atob(id)}
        communicationMode={ViewProject?.communicationMode}
      />
      <RemoveModal
        isRemoveModalhandle={isRemoveModalhandle}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setRemoveModalHandle={setRemoveModalHandle}
      />
    </>
  );
}
export default ViewProject;

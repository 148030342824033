import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialTableState = {
  ProjectList: [],
  isLoading: false,
  isError: "",
  createSuccess: false,
  totalPages: 0,
};

const TableSlice = createSlice({
  name: "Project Table",
  initialState: initialTableState,
  reducers: {
    tableAPI(state) {
      state.isLoading = true;
    },
    ProjectListAPISuccess(state, action) {
      state.isLoading = false;
      state.ProjectList = action.payload;
      state.createSuccess = true;
      state.totalPages = action.payload.count;
    },
    ProjectListAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export const ProjectListGetAction = (limit, currentPage, searchQuery) => {
  return async (dispatch) => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    const userId = userData.id;

    console.log({ userData });
    let baseURL = `${process.env.REACT_APP_API_BASEURL}/project/list-of-projects?limit=${limit}&page=${currentPage}&user_id=${userId}`;

    if (searchQuery) {
      baseURL += `&search=${searchQuery}`;
    }

    try {
      dispatch(TableSlice.actions.tableAPI());
      const token = localStorage.getItem("token");
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(
        TableSlice.actions.ProjectListAPISuccess(response.data.responseData)
      );
    } catch (error) {
      dispatch(TableSlice.actions.ProjectListAPIFailure(error.message));
    }
  };
};

export const ProjectListAction = TableSlice.actions;
export default TableSlice.reducer;

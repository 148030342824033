import React, { useState, useEffect } from "react";
import "../CSS/Header.css";
import Logo from "../assets/Logo.png";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { TopTableGetAction } from "../store/UserAction/table";
import { ProjectListGetAction } from "../store/ProjectAction/ProjectListReducer";
import Admin from "../assets/Admin.svg";
import {
  CheckAccessCode,
  project_access_code,
  users_access_code,
} from "../Utils/Helper/constant";
const Header = ({ addButton }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const storedUserData = localStorage.getItem("userData");
  const data = JSON.parse(storedUserData);
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (location.pathname === "/project")
        dispatch(ProjectListGetAction(5, 1, searchTerm));
      else if (location.pathname === "/user")
        dispatch(TopTableGetAction(5, 1, searchTerm));
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [location.pathname, searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
      <div>
        <header class="header header_box">
          <a class="header-brand" href="#">
            <img className="Logo-img" src={Logo} alt="" />
          </a>
          <div className="header_heading">
            <ul class="header-nav mr-auto">
              <li class="nav-item active">
                {location.pathname === "/project" ? (
                  <a className="nav-link header_heading" href="#">
                    Projects
                  </a>
                ) : location.pathname === "/user" ? (
                  <a className="nav-link header_heading" href="#">
                    Users
                  </a>
                ) : location.pathname.startsWith("/ViewProject") ? (
                  <a className="nav - link view-project" href="#">
                    Projects
                  </a>
                ) : (
                  <a className="nav-link header_heading" href="#"></a>
                )}
              </li>
            </ul>
          </div>
          {!location.pathname.startsWith("/ViewProject/") &&
            (CheckAccessCode(users_access_code?.user_add_users) ||
              CheckAccessCode(project_access_code?.project_search)) && (
              <form className="d-flex flexBox">
                <input
                  className="form-control me-2 search_box"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleInputChange}
                />
                {CheckAccessCode(project_access_code?.project_add_project) &&
                  addButton}
              </form>
            )}
          <div>
            <img
              src={Admin}
              style={{
                ...(location.pathname === "/project" ? {} : {}),
                ...(location.pathname.startsWith("/ViewProject")
                  ? { position: "relative", left: "21.5rem" }
                  : {}),
              }}
            />
          </div>

          <div class="david-spade">
            {String(data?.firstName) + "  " + String(data?.secondName)}
            <br /> {data?.role}
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

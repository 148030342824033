import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ValidateFileAPI } from "./ValidateFile";

const initialApplicationState = {
  isLoading: false,
  UploadFileRes: "",
  isError: "",
};

const UploadFileSlice = createSlice({
  name: "UploadFile",
  initialState: initialApplicationState,
  reducers: {
    UploadApiRequested(state) {
      state.jobRes = "";
      state.isError = "";
    },

    UploadAPISuccess(state, action) {
      state.isLoading = false;
      state.UploadFileRes = action.payload;
      state.isError = "";
    },

    UploadAPIFailure(state, action) {
      state.isLoading = false;
      state.UploadFileRes = "";
      state.isError = action.payload;
    },
  },
});

export const UploadFileAPI = (communicationMode, projectID, file) => {
  return (dispatch) => {
    dispatch(createApplicationActions.UploadApiRequested());
    const formData = new FormData();
    formData.append("excelSheet", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/production/upload-batch?communicationMode=${communicationMode}&projectId=${projectID}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res?.data?.responseStatus?.statusCode === 1) {
          dispatch(createApplicationActions.UploadAPISuccess());
          dispatch(ValidateFileAPI(communicationMode, projectID));
        } else {
          dispatch(createApplicationActions.UploadAPIFailure());
        }
      })
      .catch((err) => {
        dispatch(createApplicationActions.UploadAPIFailure());
      });
  };
};

export const createApplicationActions = UploadFileSlice.actions;
export default UploadFileSlice.reducer;

export const sidebar_access_code = {
  sidebar_users: "177J7212Q95947OE",
  project_users: "315A5298J22683GP",
};
export const users_access_code = {
  user_search: "579D6038X33636PQ",
  user_add_users: "940S3386I26767DA",
  user_edit_users: "381N6194H83178KE",
  user_delete_users: "119S6290X18848LU",
};
export const project_access_code = {
  project_search: "232D6702G94339UP",
  project_add_project: "445P3697I38834FH",
  project_edit_project: "890Y7009Y41239FU",
  project_view_project: "411O8210I25104QN",
  project_delete_project: "935X8369Z61823KP",
};
export const CheckAccessCode = (key) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData.elementAccess?.find((item) => item?.elementCode === key);
};

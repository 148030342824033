import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectListGetAction } from "../ProjectAction/ProjectListReducer";
const initialData = {
  isLoading: false,
  disableresult: "",
  error: "",
};

const deleteSlice = createSlice({
  name: "Delete Project",
  initialState: initialData,
  reducers: {
    deleteAPIRequested(state) {
      state.isLoading = true;
    },

    deleteAPISuccess(state, action) {
      state.isLoading = false;
      state.disableresult = action.payload;
    },

    deleteAPIFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const DeleteProjectAction = (id, setCurrentPage) => {
  return (dispatch) => {
    dispatch(DeleteAPIActions.deleteAPIRequested());

    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_API_BASEURL}/project/delete-project-by-id?projectId=${id}`;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    axios
      .delete(url, { headers })
      .then((response) => {
        if (response && response?.status === 200) {
          dispatch(DeleteAPIActions.deleteAPISuccess(response.data));
          dispatch(ProjectListGetAction(5, 1));
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        dispatch(DeleteAPIActions.deleteAPIFailure(error));
      });
  };
};

export const DeleteAPIActions = deleteSlice.actions;
export default deleteSlice.reducer;

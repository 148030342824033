import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Components/Login.js";
import Project from "../pages/Project.js";
import User from "../pages/User.js";
import ViewProject from "../pages/ViewProject.js";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/project" element={<Project />} />
      <Route path="/user" element={<User />} />
      <Route path="/ViewProject/:id" element={<ViewProject />} />
    </Routes>
  );
};

export default Routing;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UploadFileAPI } from "./UploadBatch";
import { ProdTableGetAction } from "../ProjectAction/ProductionTable";
const initialApplicationState = {
  isLoading: false,
  ValidateFileRes: "",
  isError: "",
};

const ValidateFileSlice = createSlice({
  name: "ValidateFile",
  initialState: initialApplicationState,
  reducers: {
    ValidateApiRequested(state) {
      state.isError = "";
    },

    ValidateAPISuccess(state, action) {
      state.isLoading = false;
      state.ValidateFileRes = action.payload;
      state.isError = "";
    },

    ValidateAPIFailure(state, action) {
      state.isLoading = false;
      state.ValidateFileRes = "";
      state.isError = action.payload;
    },
  },
});

export const ValidateFileAPI = (
  communicationMode,
  file,
  projectId,
  closeUploadModal
) => {
  return (dispatch) => {
    dispatch(ValidateFileSlice.actions.ValidateApiRequested());
    const formData = new FormData();
    formData.append("excelSheet", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/production/validate-batch?communicationMode=${communicationMode}&project=${projectId}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res?.data?.responseStatus?.statusCode === 1) {
          dispatch(
            ValidateFileSlice.actions.ValidateAPISuccess(
              res?.data?.responseData
            )
          );
          dispatch(UploadFileAPI(communicationMode, projectId, file));
          closeUploadModal();
        } else {
          dispatch(
            ValidateFileSlice.actions.ValidateAPIFailure(
              res?.data?.responseData?.message
            )
          );
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        dispatch(
          ValidateFileSlice.actions.ValidateAPIFailure("Validation failed")
        );
      });
  };
};

export const createApplicationActions = ValidateFileSlice.actions;
export default ValidateFileSlice.reducer;
